// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import 'lib-flexible'

import store from './store'
Vue.prototype.$store = store


import {
    Toast,
    List,
    Loading,
    Popup,
    Overlay,
    Icon,
    Search,
    Lazyload
} from 'vant';
Vue.use(Toast).use(List).use(Loading).use(Popup).use(Overlay).use(Icon).use(Search);



// 配置项
const loadimage = require('./assets/lazy.png')
Vue.use(Lazyload, {
  preLoad: 1.3, //预加载的宽高比
  loading: loadimage, //图片加载状态下显示的图片
  // error: errorimage, //图片加载失败时显示的图片
  attempt: 1, // 加载错误后最大尝试次数
})


// 配置请求
let aSrc = "https://game.munimob.com/gamemarket/api"
import axios from 'axios'

// axios.defaults.baseURL = 'api/';        // 跨域设置
axios.defaults.baseURL = aSrc; // 线上域名

axios.defaults.timeout = 20000;
Vue.prototype.$http = axios;


// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     navigator.serviceWorker.register('/service-worker.js').then(registration => {
//       console.log('ServiceWorker registration successful with scope: ', registration.scope);
//     }, error => {
//       console.log('ServiceWorker registration failed: ', error);
//     });
//   });
// }

Vue.config.productionTip = false;
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app")